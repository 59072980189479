import React from 'react';
import PropTypes from 'prop-types';
import { addressComponentsToString } from 'Helpers/locations';
import LocationOption from '../LocationOption/LocationOption';
import css from './LocationSuggestions.scss';
import classnames from 'classnames';
import { TYPE_SEARCH } from 'Constants/tracking';

const LocationSuggestions = ({ suggestions, onChange, searchTerm, suggestionsListClass, customSuggestionListClass, locationOptionClass }) => (
    <div className={ classnames(suggestionsListClass, css.locationSuggestions, customSuggestionListClass) }>
        <div className={ css.containerOptions }>
            { suggestions && suggestions.map(location => {
                const onSelect = () => onChange(location, { location_type: TYPE_SEARCH });

                return (
                    <LocationOption
                        key={ location.id }
                        name={ addressComponentsToString(location.addressComponents) || location.name }
                        highlight={ searchTerm }
                        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                        classes={ { locationOption: locationOptionClass } }
                        // eslint-disable-next-line react/jsx-no-bind
                        onSelect={ onSelect } />
                );
            })}
        </div>
    </div>
);

LocationSuggestions.propTypes = {
    onChange: PropTypes.func.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        addressComponents: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string
        }))
    })),
    searchTerm: PropTypes.string,
    suggestionsListClass: PropTypes.string,
    customSuggestionListClass: PropTypes.string,
    locationOptionClass: PropTypes.string
};

export default LocationSuggestions;
