import React from 'react';
import PropTypes from 'prop-types';
import css from './LocationSection.scss';
import { FormattedMessage as Translation } from 'react-intl';
import LocationOption from '../LocationOption/LocationOption';
import { TYPE_POPULAR } from 'Constants/tracking';

const PopularLocations = ({ popularLocations = [], maxLocations, onChange }) => {
    const populars = popularLocations && popularLocations.slice(0, maxLocations);

    return populars.length ? (
        <div className={ css.sectionLocations }>
            <div className={ css.sectionLocationsTitle }>
                <Translation id="popularLocations" />
            </div>
            <div>
                { populars.map((popular, key) => {
                    const onSelect = () => onChange(popular, { location_type: TYPE_POPULAR });

                    return (
                        // eslint-disable-next-line react/jsx-no-bind
                        <LocationOption key={ key } name={ popular.name } onSelect={ onSelect } />
                    );
                })}
            </div>
        </div>
    ) : null;
};

PopularLocations.propTypes = {
    onChange: PropTypes.func.isRequired,
    maxLocations: PropTypes.number.isRequired,
    popularLocations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    }))
};

export default PopularLocations;
